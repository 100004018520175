<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
    >
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title> {{ poData.POCode }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="clickCloseMainDialogPR()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
<div>
          <v-row class="mt-4">
            <v-col cols="12" class="d-flex justify-center">
              <v-subheader class="font-weight-bold text-h6"
                >Purchase Order</v-subheader
              >
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">PO no.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.POCode }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Supplier:</v-subheader>
            </v-col>
           <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.CompanyName }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold"
                >Place of Delivery:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.podDescription }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold"
                >Supplier Address:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.CompanyAddress }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold"
                >Date of Delivery:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.dodDescription }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">TIN:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.TIN }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Delivery Term:</v-subheader>
            </v-col>
           <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.dtDescription }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold"
                >Mode of Procurement:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.mopDescription }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Payment Term:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ poData.ptDescription }}</v-subheader>
            </v-col>
          </v-row>
        </div>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                v-model="formData.Lists"
                :headers="headers"
                :items="itemList"
                item-key="POItemID"
                class="elevation-1 mt-4"
                show-select
                :loading="itemLoading"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2">List Of Items</span>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="amber darken-4 "
                      outlined
                      class="mb-2 ml-4"
                      @click="iar()"
                      :disabled="!formData.Lists.length"
                    >
                    <v-icon class="mr-1">mdi-package-variant-closed-remove</v-icon>
                      Waive Items
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost_pr`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost_pr || 0) }}</span>
                </template>
                <template v-slot:[`item.UnitCost_po`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost_po || 0) }}</span>
                </template>
                <template v-slot:[`item.Qty_pr`]="{ item }">
                  {{ formatQty(item.Qty_pr) || 0 }}
                </template>
                <template v-slot:[`item.Qty_po`]="{ item }">
                  {{ formatQty(item.Qty_po) || 0 }}
                </template>
                <template v-slot:[`item.Qty_iar`]="{ item }">
                  {{ formatQty(item.Qty_iar) || 0 }}
                </template>
                <template v-slot:[`item.Qty_waived`]="{ item }">
                  {{ formatQty(item.Qty_waived) || 0 }}
                </template>
                <template v-slot:[`item.Qty_waive`]="{ item }">
                  {{ formatQty(item.Qty_waive) || 0 }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="PurposeDialog" persistent max-width="1000px" scrollable>
      <v-card>
        <v-card-title>
          <span class="">Waive Purchase Order</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="PurposeDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="max-height: 900px">
            <v-form @submit.prevent ref="Formref">
              <v-row>
                <v-col lg="12">
                <v-data-table
                    :headers="headers2"
                    :items="formData.Lists"
                    item-key="POItemID"
                    class="elevation-1 mt-4"
                >
                <template v-slot:top>
                  <v-toolbar flat>
                    <strong>List of Items Selected</strong>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
                    <template v-slot:[`item.UnitCost_waived`]="{ item }">
                    <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost_waived || 0) }}</span>
                    </template>
                    <template v-slot:[`item.UnitCost_po`]="{ item }">
                    <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost_po || 0) }}</span>
                    </template>
                    <template v-slot:[`item.Qty_lcb`]="{ item }">
                    {{ formatQty(item.Qty_lcb) || 0 }}
                    </template>
                    <template v-slot:[`item.UnitCost_new`]="{ item }">
                    <v-edit-dialog
                        large
                        :return-value.sync="item.UnitCost_new"
                    >
                    <span class="text-no-wrap" :style="parseFloat(item.UnitCost_pr) < parseFloat(item.UnitCost_new) || parseFloat(item.UnitCost_new) <= 0 ? 'color:red' : 'color:green'">₱ {{ formatPrice(item.UnitCost_new || 0) }}</span>
                    <template v-slot:input>
                    <v-text-field
                        v-model="item.UnitCost_new"
                        :rules="[parseFloat(item.UnitCost_pr) >= parseFloat(item.UnitCost_new), parseFloat(item.UnitCost_new) > 0]"
                        type="number"
                    >
                    </v-text-field>
                    </template>
                    </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.Qty_new`]="{ item }">
                        <v-edit-dialog
                        large
                        :return-value.sync="item.Qty_new"
                    >
                        <span
                        :style="parseFloat(item.Qty_waive) < parseFloat(item.Qty_new) || parseFloat(item.Qty_new) <= 0 ? 'color:red' : 'color:green'">
                    {{ formatQty(item.Qty_new) || 0 }}
                    </span>
                    <template v-slot:input>
                    <v-text-field
                        v-model="item.Qty_new"
                        :rules="[parseFloat(item.Qty_waive) >= parseFloat(item.Qty_new), parseFloat(item.Qty_new) > 0]"
                        type="number"
                    >
                    </v-text-field>
                    </template>
                    </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.Amount_new`]="{ item }">
                    <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost_new) * parseFloat(item.Qty_new) || 0) }}</span>
                    </template>
                </v-data-table>
                </v-col>
              </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" outlined @click="PurposeDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-2" class="white--text" @click="submitWaive()">
            <v-icon class="mr-1">mdi-check-circle</v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmSubmit" max-width="500px">
          <v-card>
            <v-card-title class="headline pt-5">Confirmation</v-card-title>
            <div class="mx-6">
              Are you sure you want to waive the items/s of this Purchase Order?
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogConfirmSubmit = false">Cancel</v-btn>
              <v-btn color="green darken-2 white--text" @click="confirmSubmitWaive()"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    poData: {},
  },
  data: () => ({
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    formData: {
      Lists: [],
      PRDATA: [],
      dtID: null,
      dodID: null,
      mopID: null,
      ptID: null,
      SupplierID: null,
      podID: null,
      notes: '',
    },
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    itemList: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost_po", align: "center", sortable: false },
      { text: "Quantity (PO)", value: "Qty_po", align: "center", sortable: false },
      { text: "Quantity (IAR)", value: "Qty_iar", align: "center", sortable: false },
      { text: "Quantity (Waived)", value: "Qty_waived", align: "center", sortable: false },
      { text: "Quantity (waivable)", value: "Qty_waive", align: "center", sortable: false },
    ],
    headers2: [
      {
        text: "Stock/Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost_po", align: "center", sortable: false },
      { text: "Qty (PO)", value: "Qty_po", align: "center", sortable: false },
      { text: "Qty (Waivable)", value: "Qty_waive", align: "center", sortable: false },
      { text: "Qty (Editable)", value: "Qty_new", align: "center", sortable: false },
      { text: "Total Cost", value: "Amount_new", align: "center", sortable: false },
    ],
    itemLoading: false,
    deliveryterm: [],
    supplier: [],
    dod: [],
    mop: [],
    paymentterm: [],
    pod: [],
    dialogConfirmSubmit: false,
  }),
  watch: {
    poData: {
      handler(data) {
        if (data.POID) {
          this.getItems();
          this.getPOUtil();
          this.getPRData();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    iar() {
      this.PurposeDialog = true;
    },
    submitWaive() {
      if (this.$refs.Formref.validate()) {
        let error = false;
        for (let list of this.formData.Lists) {
          if (
            parseFloat(list.UnitCost_po) < parseFloat(list.UnitCost_new) ||
            parseFloat(list.UnitCost_new) <= 0 || parseFloat(list.Qty_new) <= 0
          ) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
          this.PurposeDialog = false;
        } else {
            this.dialogConfirmSubmit = true;
        }
      }
      else{
        this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
      }
    },
    confirmSubmitWaive() {
      if (this.$refs.Formref.validate()) {
        let error = false;
        for (let list of this.formData.Lists) {
          if (
            parseFloat(list.UnitCost_po) < parseFloat(list.UnitCost_new) ||
            parseFloat(list.UnitCost_new) <= 0 || parseFloat(list.Qty_new) <= 0
          ) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
          this.PurposeDialog = false;
        } else {
          let data = new FormData();
            data.append("List", JSON.stringify(this.formData.Lists));
            data.append("POID", this.poData.POID);
            this.axiosCall("/waive/add", "POST", data).then((res) => {
            if(res.data.status){
              this.getItems();
              this.PurposeDialog = false;
              this.dialogConfirmSubmit = false;
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "success";
              this.fadeAwayMessage.message = "Purchase Order items was successfully waived.";
              this.fadeAwayMessage.header = "System Message";
            }
            else{
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "error";
              this.fadeAwayMessage.message = "Something went wrong!";
              this.fadeAwayMessage.header = "System Message";
            }
            });
        }
      }
    },
    clickCloseMainDialogPR() {
      this.formData.Lists = [];
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeIAR", false);
    },

    getItems() {
    this.itemLoading = true;
      this.formData.Lists = [];
      let data = new FormData();
      data.append("POID", this.poData.POID);
      this.axiosCall("/po/getItems/waive", "POST", data).then((res) => {
        this.itemLoading = false;
        this.itemList = res.data.data.result;
        this.users = res.data.data.users;
      });
    },
    getPOUtil() {
      let data = new FormData();
      this.axiosCall("/get/all/POUtil", "POST", data).then((res) => {
        this.deliveryterm = res.data.data.deliveryterm;
        this.supplier = res.data.data.supplier;
        this.dod = res.data.data.dod;
        this.mop = res.data.data.mop;
        this.paymentterm = res.data.data.paymentterm;
        this.pod = res.data.data.pod;
      });
    },
    getPRData() {
      this.formData.PRDATA = [];
      let data = new FormData();
      data.append("prid", this.poData.prid);
      this.axiosCall("/get/approved/specificPR", "POST", data).then((res) => {
        this.formData.PRDATA = res.data.data;
      });
    },
    remove(date, field) {
      let data = [];
      if(field == 'invoice'){
        data = this.formData.DateInvoice;
      }
      else if(field == 'delivered'){
        data = this.formData.DateDelivered;
      }
      var index = data.findIndex(x => x===date)
       data.splice(index,1);
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>